import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  constructor(
    public data: DataService
  ){}

  /**
   * 
   * Get tradução
   * 
   */
  getTraducao(value,linguagem){

    return typeof(this.parse()[linguagem][value]) != "undefined" ? this.parse()[linguagem][value] : value;

  }
  /**
   * 
   * Parse
   * 
   */
  parse(){

    return {
      'en': { 
        'ADICIONAR PASSEIO': 'ADD TOUR',
        'FINALIZAR COMPRA': 'FINALIZE PURCHASE',
        'ADICIONAR AO CARRINHO': 'ADD TO CART',
        'Clique para Visualizar': 'Click to View',
        'Aceito as condições': 'I accept the conditions',
        'DADOS DO PAGAMENTO': 'DETALLES DEL PAGO', 
        'Resumo da Compra': 'Purchase Summary',
        'Taxa:' : 'Fee:',
        'SOBRE O EVENTO': 'ABOUT THE EVENT',
        'FAÇA SUA RESERVA': 'MAKE YOUR RESERVATION',
        'Reservar Agora': 'Book Now',
        'Fale com a nossa equipe?': 'Talk to our team?',
        'NOSSA ESSÊNCIA': 'OUR ESSENCE',
        'nosso DNA': 'our DNA',
        'Conheça o': 'meet the',
        'jeito': 'way',
        'Mostrar mais': 'More',
        'Horário Saída': 'Departure Time',
        'Quem Somos': 'About Us',
        'Contato': 'Contact',
        'Protocolos': 'Protocols',
        'Conheça Paraty': 'About Paraty',
        'Entrar': 'Login',
        'O que você procura?': 'What are you looking for?',
        'Trabalhe Conosco': 'Work with us',
        'Nossa História': 'Our story',
        'Políticas de Cancelamento': 'Cancellation Policies',
        'Políticas de Privacidade': 'Privacy Policies',
        'Tire suas dúvidas': 'Ask your questions',
        'Missão': 'Mission',
        'Visão': 'Vision',
        'Propósito': 'Purpose',
        'Informe seu nome*': 'Inform your name*',
        'Informe seu e-mail*': 'Inform your e-mail*',
        'Telefone': 'Phone',
        'Informe o assunto*': 'Inform the subject*',
        'Escreva sua mensagem...': "Write your message...",
        'ENVIAR': 'SUBMIT',
        'Entre em Contato': 'Contact',
        'Ligue Agora': 'Call Now',
        'Nos envie um e-mail': 'Send us an e-mail',
        'Atendimento por telefone:': 'Telephone service:',
        'Fale pelo WhatsApp': 'Talk by WhatsApp',
        'Informe seu nome': 'Enter your name',
        'Informe seu e-mail': 'Enter your email',
        '- Informe seu e-mail.': '- Enter your email.',
        'Veja nossa frota': 'See our fleet',
        'Transfer Compartilhado': 'Regular Transfer',
        'Transfer Privativo': 'Private Transfer',
        'Regular': 'Regular',
        'Privativo': 'Private',
        'CONSULTE': 'CONSULT',
        'sem juros': 'interest-free',
        'Localização da Atividade': 'Activity Location',
        'Dúvidas Frequentes': 'Frequently Asked Questions',
        'O que inclui': 'What includes',
        'O que não inclui': 'What does not include',
        'Veja também estas experiências': 'See also these experiences:',
        'Ver e Reservar': 'View and Book',
        'a partir de': 'from',
        'A Partir de': 'from',
        'por pessoa': 'per person',
        'De': 'From',
        'por': 'for',
        'Valor': 'Price',
        'Todos os dias da semana.': 'Every day of the week.',
        'Segundas': 'Mondays',
        'Terças': 'Tuesdays',
        'Quartas': 'Wednesdays',
        'Quintas': 'Wednesdays',
        'Sextas':  'Fridays',
        'Sábados': 'Saturdays',
        'Domingos': 'Sundays',
        'e': 'and',
        'Duração': 'Duration',
        'Tipo de Atividade': 'Activity Type',
        'Local de Embarque': 'Boarding Place',
        'Destino': 'Place',
        'horas': 'hours',
        'hora': 'hour',
        'minuto': 'minute',
        'Nome': 'Name',
        'Informe seus dados e entre em contato': 'Enter your details and contact us',
        'E-mail': 'Email',
        'INFORME A QUANTIDADE DE PASSAGEIROS': 'INFORM THE NUMBER OF PASSENGER',
        'Adultos': 'Adults',
        'Melhor Idade': 'Best Age',
        'Crianças Pagantes': 'Paying Children',
        'Crianças Cortesia': 'Free',
        'Escreva sua dúvida': 'Write your question',
        'Escreva o que você precisa aqui. Logo entraremos em contato.': 'Write what you need here. We will contact you soon.',
        'Enviar': 'Send',
        'Por': 'By',
        'LEIA MAIS': 'READ MORE',
        'Mais populares': 'Mor popular',
        'Veja os posts mais populares': 'See the most popular posts',
        'POSTS RECENTES': 'RECENT POSTS',
        'Seu nome': 'Your name',
        'Seu e-mail': 'Your email',
        'Fique por dentro de todas as nossas dicas, promoções e novidades!': 'Stay on top of all our tips, promotions and news!',
        'Veja também': 'More posts',
        'duração aproximada': 'approximate duration',
        'Este ambiente é 100% seguro. Seus dados pessoais e seus dados de pagamento estarão protegidos e criptografados.': 'This environment is 100% secure. Your personal data and your payment details will be protected and encrypted.',
        'Resumo': 'Summary',
        'TIPO DE SERVIÇO': 'KIND OF SERVICE',
        'Próximo': 'Next',
        'PRÓXIMO': 'NEXT',
        'Anterior': 'Previous',
        'ONDE VAI SE HOSPEDAR?': 'WHERE WILL YOU STAY?',
        'Esse serviço está indisponível para compra.': 'This service is unavailable for purchase.',
        'Informe o Endereço': 'Enter the Address',
        'ESCOLHA A DATA': 'CHOOSE THE DATE',
        'Adicionar ao Carrinho': 'Add to Cart',
        'INFORME OS DADOS DO VÔO': 'REPORT FLIGHT DATA',
        'Dados de Chegada': 'Arrival Data',
        'Dados de Retorno': 'Return Data',
        'Nº do Voo': 'Flight number',
        'Hora do Voo': 'Flight time',
        'Data Partida': 'Departure Date',
        'Crianças': 'Kids',
        'Cortesia': 'Free',
        'Continuar comprando': 'Keep buying',
        'Finalizar minhas compras': 'Check out my purchases',
        'Selecionar Hospedagem': 'Select hosting',
        'Criança Pagante': 'Paying Child',
        'Criança Cortesia': 'Free',
        'foi adicionado ao carrinho!': 'has been added to cart!',
        'Carrinho de Compras': 'Shopping cart',
        'CÓDIGO': 'CODE',
        'adulto': 'adult',
        'melhor idade': 'best age',
        'criança pagante': 'paying child',
        'Você ainda não tem serviços adicionados no carrinho de compras.': 'You do not have any services added to your shopping cart yet.',
        'Seguir para o Pagamento': 'Go to Payment',
        'Valor final da compra': 'Final purchase value',
        'ou em': 'or',
        'de': 'of',
        'EXCLUIR': 'DELETE',
        'Excluir': 'Delete',
        'Faça o login para continuar': 'Login to continue',
        'Informe sua senha': 'Inform your password',
        'Finalize sua Compra': 'Complete your purchase',
        'Login com Facebook': 'Login with Facebook',
        'Login com Google': 'Login with Google',
        'Voltar': 'Come Back',
        'Deseja se cadastrar?': 'Do you want to register?',
        'Clique aqui': 'Click here',
        'Clique Aqui': 'Click here',
        'Esqueceu a senha?': 'Forgot password?',
        '- Informe o E-mail.': '- Inform the E-mail.',
        '- O E-mail informado é inválido.': '- The E-mail entered is invalid.',
        'Cadastrar': 'Register',
        'Informe uma senha': 'Entre a password',
        'Data de Nascimento': 'Birth date',
        'Informe seu celular': 'Inform your cell phone',
        'Informe seu CPF': 'Inform your CPF',
        'Informe seu RG': 'Inform your RG',
        'Alterar Senha': 'Change Password',
        'Informe a nova senha': 'Enter the new password',  
        'Informe o código enviado no seu e-mail': 'Enter the code sent in your email',
        'Verifique o código que foi enviado para o seu e-mail.': 'Check the code that was sent to your email.',
        'Houve um erro e não foi possível completar a requisição. Tente novamente.': 'There was an error and the request could not be completed. Try again.',
        '- Informe o Código.': '- Inform the Code.',
        '- Informe a Nova Senha.': '- Enter New Password.', 
        'Houve um erro interno. Tente novamente ou entre em contato com a nossa equipe.': 'There was an internal error. Please try again or contact our team.',
        'A sua senha foi alterada com sucesso.': 'Your password has been successfully changed.',
        'Dados do Comprador': 'Buyer Data',
        'Informe o cupom de desconto': 'Enter the discount coupon',
        'Informe os dados dos passageiros': 'Enter passenger data',
        'Pagar e finalizar meu pedido': 'Pay and checkout my order',
        'Passageiro': 'Passenger',  
        'Preencha os dados dos passageiros': 'Fill in the passenger details',
        'Documento': 'Document',
        'Essa parte é superimportante.': 'This part is super important.',
        'Você apenas receberá seu voucher quando preencher os dados completos.': 'You will only receive your voucher when you fill in the complete details.',
        'Você pode': 'You can',
        'Preencher Agora': 'Fill in now',
        'Essa parte é muito importante.': 'This part is very important.',
        'Observações': 'Observations',
        'Escreva uma observação': 'Write a note',
        'Selecione um passageiro': 'Select a passenger',
        'Preencha os dados de pagamento': 'Fill in the payment details',
        'Nome escrito no cartão': 'Name written on card',
        'Número do cartão': 'Card number',
        'Data de Validade': 'Expiration Date',
        'Parcelas:': 'Installments:',
        'Titular da Reserva': 'Reserve Holder',
        'Nome Completo': 'Full Name',
        'Detalhes da sua compra': 'Details of your purchase',
        'Total a Pagar': 'Total',
        'compra realizada': 'purchase made',
        'com sucesso': 'successfully',
        'Sua compra e seu pagamento foram efetuados com sucesso': 'Your purchase and payment were successful',
        'Logo você receberá no seu e-mail uma confirmação e as informações do voucher.': 'You will soon receive a confirmation and voucher information in your email.',
        'Seu voucher também estará agora disponível na página da sua conta aqui no site da Paraty Tours': 'Your voucher will also now be available on your account page here on the Paraty Tours website.',
        'VER MEUS PEDIDOS': 'VIEW MY ORDERS',
        'Data da Compra': 'Purchase Date',
        'Total investido': 'Total',
        'Meu Perfil': 'My Profile',
        'Meus Favoritos': 'My Favorites',
        'Sair da minha conta': 'Logout',
        'Passeios em Brasília': 'Tours in Brasilia', 
        'Os melhores carros para o seu transporte': 'The best cars for your transport',
        'As melhores dicas sobre a região': 'The best tips about the region',
        'Me Cadastrar': 'Register me',
        'Informe seu telefone': 'Inform your phone',
        'Sua mensagem': 'Your message',
        'Mensagem': 'Message',
        'Informe aqui o seu melhor e-mail': 'Enter your best email here',
        'Escreva aqui a sua mensagem para a nossa equipe': 'Write your message to our team here',
        'Precisa de ajuda?': 'Need help ?',
        'Ver mais do nosso trabalho': 'See more of our work',
        'Detalhes e Reservar': 'Details and Book',
        'Duração Aproximada': 'Approximate Duration',
        'Dias da Semana': 'Days of the week',
        'Clica em': 'Click on',
        'Adicionar no Carrinho': 'Add to Cart',
        'para preencher os dados e adicionar esse serviço no seu': 'to fill in the details and add this service to your',
        'pedido': 'order',
        'Preencha os dados para Adicionar no Carrinho': 'Fill in the details to add to cart',
        'Este serviço tem mais de 1 horário': 'This service has more than 1 schedule',
        'Escolha o Horário': 'Choose Time',
        'Verifique os dados e adicione ao carrinho': 'Check data and add to cart',
        'Tipo de Serviço': 'Kind of Service',
        'Data Utilização': 'Date Usage',
        'foi adicionado no carrinho': 'has been added to cart',
        'Finalizar a Reserva': 'Go to payment',
        'Reserve clicando no botão abaixo':  'Book by clicking the button below',
        'Salvar nos Favoritos': 'Save to Favorites',
        'está salvo nos favoritos': 'is saved in favorites',
        'Este serviço já está no seu carrinho': 'This service is already in your cart',
        'Ir para o Carrinho': 'Go to cart',
        'Deseja retirar': 'Do you want to',
        'do carinho': 'from cart',
        'Sim': 'Yes',
        'Não': 'Not',
        'Meu Pedido': 'My Cart',
        'Pagamento': 'Payment',
        'Confirmação': 'Confirmation',
        'Seu carrinho está vazio': 'Your shopping cart is empty',
        'Formas de Pagamento': 'Payment Methods',
        'Acessar minha conta': 'Access my account',
        'Se precisar de algo, estamos aqui': 'If you need anything, we are here',
        'Vouchers (ingressos)': 'Vouchers (tickets)',
        'A sua reserva foi confirmada': 'Your reservation has been confirmed',
        'Informações da sua reserva': 'Your booking information',
        'Voltar para a página inicial': 'Back to home page',
        'Você ainda não tem serviços para listar.': 'You have no services to list yet.',
        'Pesquise aqui': 'Search Here',
        'Nome do Serviço': 'Service Name',
        'Tipo da Experiência': 'Experience Type',
        'Para seu perfil': 'To your profile',
        'Ordenar por': 'Order by',
        'Menor Preço': 'Lowest price',
        'Maior Preço': 'Biggest price',
        'Faça o seu login informando o seu e-mail e senha ou através da rede social do google e facebook.': 'Log in by entering your email and password or through the social network google and facebook.',
        'Avançar': 'Next',
        'Faça o seu login na Suprematur': 'Login to Suprematur',
        'Houve um problema ao logar. Tente novamente ou entre em contato com nossa equipe.': 'There was a problem logging in. Please try again or contact our team.',
        'Digite sua senha para fazer login em sua conta. Caso tenha esquecido,': 'Enter your password to log into your account. If you forgot,',
        'recupere sua senha aqui.': 'retrieve your password here.',
        'Informe o e-mail que você deseja receber o código.': 'Enter the email you want to receive the code.',
        'Tenho um Código': 'I have a code',
        'Informe o código que você recebeu no seu e-mail e a nova senha.': 'Enter the code you received in your email and the new password.',
        'Deseja sair da sua conta?': 'Do you want to log out of your account?',
        'Seu cadastro foi realizado com sucesso.': 'Your registration was successful.',
        'Nosso E-mail': 'Our Email',
        'Fale por WhatsApp': 'Talk by WhatsApp',
        'Envie uma Mensagem': 'Send a message',
        'Fale Conosco': 'Contact us',
        'Sobre a Suprematur': 'About Suprematur',
        'Me Carrinho': 'My Cart',
        'Minhas Viagens': 'My Travels',
        'Políticas de Cookies': 'Cookies Policies',
        'Nossa Missão': 'Our mission',
        'Nossa Visão': 'Our Vision',
        'Nossos Valores': 'Our Values',
        'Nossos': 'Our',
        'Valores': 'Values',
        'Acesse': 'Access',
        'Passeios': 'Tours',
        'Transfers': 'Transfers',
        'Nosso Blog': 'Our Blog',
        'Início': 'Home',
        'Posts mais recentes': 'Recent Posts',
        'Não temos posts para mostrar no momento.': 'We have no posts to display at the moment.',
        'Publicado em:': 'Publicado en:',
        'Leia mais': 'Read more',
        'Continuar lendo': 'Read More',
        'Fazemos parte de(o)': 'We are part of',
        'Ver mais': 'See more',
        'Ver Mais': 'See more',
        'Não temos conteúdo para mostrar nesta página': 'We have no content to show on this page',
        'Minha Conta': 'My Account',
        'Minha conta': 'My Account',
        'Ver todos os serviços': 'See all services',
        'Ver todos os roteiros disponíveis': 'See all services',
        'Nacionalidade': 'Nationality',
        'Informe se você é brasileiro(a) ou estrangeiro(a).': 'Inform if you are Brazilian or foreign.',
        'Brasileiro(a)': 'Brazilian',
        'Estrangeiro(a)': 'Foreigner',
        'Informe seu PASSAPORTE': 'Inform your PASSPORT',
        'Taxa e Impostos:': 'Fee and Taxes:',
        'DETALHES DO HOTEL': 'HOTEL DETAILS',
        'HOSPEAGEM': 'ACCOMMODATION',
        'Ir para o Pagamento': 'Go to Payment',
        'Total à Pagar': 'Total Payable',
        'Entrar em contato com o suporte': 'Contact support',
        'Realizar o Pagamento': 'Make Payment',
        'Pagamento com PayPal': 'Payment with PayPal',
        'Clique aqui para Pagar': 'Click here to Pay'
      },
      'es': {
        'ADICIONAR PASSEIO': 'AÑADIR TOUR',
        'FINALIZAR COMPRA': 'FINALIZAR COMPRA',
        'ADICIONAR AO CARRINHO': 'AÑDIR A LA CESTA',
        'Clique aqui para Pagar': 'Haga clic aquí para pagar',
        'Realizar o Pagamento': 'Realizar pago',
        'Total à Pagar': 'Total a pagar',
        'PAGAMENTO': 'PAGO',
        'HOSPEDAGEM': 'HOSPEDAJE',
        'Clique para Visualizar': 'Click para ver',
        'Aceito as condições': 'Acepto las condiciones',
        'Resumo da Compra': 'Resumen de compra',
        'Taxa e Impostos:': 'Tarifas e impuestos:',
        'Taxa: ': 'Tasa:',
        'SOBRE O EVENTO': 'SOBRE EL EVENTO',
        'FAÇA SUA RESERVA': 'HAZ TU RESERVA',
        'Reservar Agora': 'Reservar ahora',
        'Fale com a nossa equipe?': '¿Hablar con nuestro equipo?',
        'NOSSA ESSÊNCIA': 'NUESTRA ESENCIA',
        'nosso DNA': 'nuestro ADN',
        'Conheça o': 'satisfacer la',
        'jeito': 'forma',
        'Nossos': 'Nuestros',
        'Valores': 'Valores',
        'Informe seu PASSAPORTE': 'Informa tu PASSPORTE',
        'Estrangeiro(a)': 'Extranjero',
        'Brasileiro(a)': 'bBrasileño(a)',
        'Informe se você é brasileiro(a) ou estrangeiro(a).': 'Informe si es brasileño o extranjero.',
        'Nacionalidade': 'Nacionalidad',
        'Mensagem': 'Mensaje',   
        'Ver todos os serviços': 'Ver todos los servicios',
        'Ver todos os roteiros disponíveis': 'See all services',
        'Minha Conta': 'Mi Cuenta',
        'Minha conta': 'Mi Cuenta',
        'Não temos conteúdo para mostrar nesta página': 'No tenemos contenido para mostrar en esta página.',
        'Ver mais': 'Ver más',
        'Ver Mais': 'Ver más', 
        'Fazemos parte de(o)': 'Somos parte de',
        'Continuar lendo': 'Sigue Leyendo',
        'Leia mais': 'Lea mas', 
        'Publicado em:': 'Published in:',
        'Não temos posts para mostrar no momento.': 'No tenemos publicaciones para mostrar en este momento.',
        'Posts mais recentes': 'Publicaciones más Recientes',
        'Início': 'Comienzo', 
        'Nosso Blog': 'Nuestro blog',
        'Transfers': 'Transportes', 
        'Passeios': 'Excursiones',
        'Acesse': 'Acceso',
        'Nossos Valores': 'Nuestros valores',
        'Nossa Visão': 'Nuestra visión', 
        'Nossa Missão': 'Nuestra misión',
        'Políticas de Cookies': 'Políticas de cookies',
        'Minhas Viagens': 'Mi Viajes',
        'Meu Carrinho': 'Mi Carrito',
        'Sobre a Suprematur': 'Sobre Suprematur',
        'Fale Conosco': 'Hable con nosotros',
        'Envie uma Mensagem': 'Enviar un mensaje',
        'Fale por WhatsApp': 'Hablar por WhatsApp',
        'Nosso E-mail': 'Nuestro correo electrónico',
        'Seu cadastro foi realizado com sucesso.': 'Su registro se realizó correctamente.',
        'Deseja sair da sua conta?': '¿Quieres salir de tu cuenta?',
        'Informe o código que você recebeu no seu e-mail e a nova senha.': 'Ingrese el código que recibió en su correo electrónico y la nueva contraseña.',
        'Tenho um Código': 'Tengo un código',
        'Informe o e-mail que você deseja receber o código.': 'Introduce el email en el que quieres recibir el código.',
        'Digite sua senha para fazer login em sua conta. Caso tenha esquecido,': 'Introduzca su contraseña para iniciar sesión en su cuenta. Si la olvidaste,',
        'recupere sua senha aqui.': 'recupera tu contraseña aquí.', 
        'Houve um problema ao logar. Tente novamente ou entre em contato com nossa equipe.': 'Hubo un problema al iniciar sesión. Vuelva a intentarlo o póngase en contacto con nuestro equipo.',
        'Faça o seu login na Suprematur': 'Iniciar sesión en Suprematur',
        'Avançar': 'Seguiente',
        'Faça o seu login informando o seu e-mail e senha ou através da rede social do google e facebook.': 'nicie sesión ingresando su correo electrónico y contraseña o a través de la red social google y facebook.',
        'Menor Preço': 'El precio más bajo',
        'Maior Preço': 'Precio mayor',
        'Ordenar por': 'Ordenar por',
        'Para seu perfil': 'A tu perfil',
        'Tipo da Experiência': 'Tipo de experiencia',
        'Nome do serviço': 'Nombre del Servicio',
        'Pesquise aqui': 'Busca Aquí',
        'Você ainda não tem serviços para listar.': 'Aún no tienes servicios para listar.',
        'Voltar para a página inicial': 'Volver a la página de inicio',
        'Informações da sua reserva': 'Tu información de reserva',
        'A sua reserva foi confirmada': 'Su reserva ha sido confirmada',
        'Vouchers (ingressos)': 'Vales (boletos)', 
        'Se precisar de algo, estamos aqui': 'Si necesitas algo, aquí estamos.',
        'Acessar minha conta': 'Acceder a mi cuenta',
        'Formas de Pagamento': 'Formas de Pago',
        'Seu carrinho está vazio': 'Su cesta está vacía',
        'Ir para o Carrinho': 'Ir al Carrito',
        'Este serviço já está no seu carrinho': 'Este servicio ya está en tu carrito',
        'está salvo nos favoritos': 'se guarda en favoritos',
        'Salvar nos Favoritos': 'Guardar en favoritos', 
        'Reserve clicando no botão abaixo':  'Reserve haciendo clic en el botón de abajo',
        'Finalizar a Reserva': 'Finalizar Reserva',
        'foi adicionado no carrinho': 'ha sido agregado al carrito',
        'Data Utilização': 'Fecha de Uso', 
        'Tipo de Serviço': 'Tipo de Servicio',
        'Verifique os dados e adicione ao carrinho': 'Consultar datos y añadir al carrito',
        'Escolha o Horário': 'Elegir hora',
        'Detalhes e Reservar': 'Detalles y Reserva',
        'Ver mais do nosso trabalho': 'Ver más de nuestro trabajo',
        'Precisa de ajuda?': 'Necesita ayuda ?', 
        'Escreva aqui a sua mensagem para a nossa equipe': 'Escribe tu mensaje a nuestro equipo aquí',
        'Informe aqui o seu melhor e-mail': 'Ingrese su mejor correo electrónico aquí',
        'Sua mensagem': 'Su mensaje',
        'Informe seu telefone': 'Informa a tu teléfono',
        'Horário Saída': 'Hora de Salida',
        'Quem Somos': 'Quienes somos',
        'Contato': 'Contacto',
        'Protocolos': 'Protocolos',
        'Conheça a Paraty': 'Conoce a Paraty',
        'Entrar': 'Entrar',
        'O que você procura?': '¿Qué buscas?',
        'Trabalhe Conosco': 'Trabaja con nosotros',
        'Nossa História': 'Nuestra historia', 
        'Políticas de Cancelamento': 'Políticas de cancelación',
        'Políticas de Privacidade': 'Políticas de privacidad',
        'Tire suas dúvidas': 'Haga sus preguntas',
        'Missão': 'Misión',
        'Visão': 'Vista',
        'Propósito': 'Propósito',
        'Informe seu nome*': 'Introduzca su nombre*',
        'Informe seu e-mail*': 'Introduce tu correo electrónico*',
        '- Informe seu e-mail.': '- Introduce tu correo electrónico.',
        'Telefone': 'Teléfono',
        'Informe o assunto*': 'Informar al sujeto*',
        'Escreva sua mensagem...': 'Escribe tu mensaje...',
        'ENVIAR': 'ENVIAR',
        'Entre em Contato': 'Entre en contacto',
        'Ligue Agora': 'llame ahora',
        'Nos envie um e-mail': 'Envianos un email',
        'Atendimento por telefone:': 'Servicio telefónico',
        'Fale pelo WhatsApp': 'Habla por WhatsApp',
        'Informe seu nome': 'Introduzca su nombre',
        'Informe seu e-mail': 'Introduce tu correo electrónico',
        'Veja nossa frota': 'Ver nuestra flota',
        'Transfer Compartilhado': 'Transporte Transfer',
        'Transfer Privativo': 'Transporte Privado',
        'Regular': 'Regular',
        'Privativo': 'Private',
        'CONSULTE': 'CONSULTE',
        'sem juros': 'sin interés',
        'Localização da Atividade': 'Ubicación de la actividad',
        'Dúvidas Frequentes': 'Preguntas frecuentes',
        'O que inclui': 'Que incluye',
        'O que não inclui': 'Lo que no incluye',
        'Veja também estas experiências': 'Vea también estas experiencias:',
        'Ver e Reservar': 'Ver y reservar',
        'a partir de': 'desde',
        'por pessoa': 'por persona',
        'De': 'From',
        'por': 'for',
        'Valor': 'Precio',
        'Todos os dias da semana.': 'Todos los días de la semana.',
        'Segundas': 'Lunes',
        'Terças': 'Martes',
        'Quartas': 'Miércoles',
        'Quintas': 'Jueves',
        'Sextas':  'Viernes',
        'Sábados': 'Sábados',
        'Domingos': 'Domingos',
        'e': 'y',
        'Duração': 'Duración',
        'Tipo de Atividade': 'Tipo de actividad',
        'Local de Embarque': 'Lugar de embarque',
        'Destino': 'Destino',
        'horas': 'horas',
        'hora': 'hora',
        'minuto': 'minuto',
        'Informe seus dados e entre em contato': 'Ingrese sus datos y contáctenos',
        'E-mail': 'Correo electrónico',
        'INFORME A QUANTIDADE DE PASSAGEIROS': 'INFORMAR EL NÚMERO DE PASAJEROS',
        'Adultos': 'Adultos',
        'Melhor Idade': 'Mejor edad',
        'Crianças Pagantes': 'Pagar a los niños',
        'Crianças Cortesia': 'Niños de cortesía',
        'Escreva sua dúvida': 'Escribe tu pregunta',
        'Escreva o que você precisa aqui. Logo entraremos em contato.': 'Escriba lo que necesita aquí. Nos pondremos en contacto con usted pronto.',
        'Enviar': 'Enviar',
        'Por': 'Por',
        'LEIA MAIS': 'LEA MAS',
        'Mais populares': 'Más popular',
        'Veja os posts mais populares': 'Ver las publicaciones más populares',
        'POSTS RECENTES': 'MENSAJES RECIENTES',
        'Seu nome': 'Su nombre',
        'Seu e-mail': 'Su e-mail',
        'Fique por dentro de todas as nossas dicas, promoções e novidades!': '¡Manténgase al tanto de todos nuestros consejos, promociones y noticias!',
        'Veja também': 'Vea también',
        'duração aproximada': 'duración aproximada',
        'Este ambiente é 100% seguro. Seus dados pessoais e seus dados de pagamento estarão protegidos e criptografados.': 'Este entorno es 100% seguro. Sus datos personales y sus datos de pago estarán protegidos y encriptados.',
        'Resumo': 'Resumen',
        'TIPO DE SERVIÇO': 'TIPO DE SERVICIO',
        'PRÓXIMO': 'SEGUIENTE',
        'Próximo': 'Seguiente',
        'Anterior': 'Anterior',  
        'ONDE VAI SE HOSPEDAR?': '¿DÓNDE TE QUEDARÁS?',
        'Esse serviço está indisponível para compra.': 'Este servicio no está disponible para su compra.',
        'Informe o Endereço': 'Ingrese la dirección',
        'ESCOLHA A DATA': 'ELIGE LA FECHA',
        'Adicionar ao Carrinho':  'Agregar al carrito',
        'INFORME OS DADOS DO VÔO': 'INFORMAR DATOS DE VUELO',
        'Dados de Chegada': 'Datos de llegada',
        'Dados de Retorno': 'Devolver datos',
        'Nº do Voo': 'Número de vuelo',
        'Hora do Voo': 'Tiempo de vuelo',
        'Data Partida': 'Fecha de salida',
        'Crianças': 'Niños',
        'Cortesia': 'Libre',
        'Continuar comprando': 'Sigue comprando',
        'Finalizar minhas compras': 'Mira mis compras',
        'Selecionar Hospedagem': 'Seleccionar hosting',
        'SELECIONAR': 'SELECCIONAR',
        'SELECIONE': 'SELECCIONAR',
        'nome*': 'nombre*',
        'email*': 'correo electrónico*',
        'telefone*': 'teléfono*',
        'Criança Pagante': 'Niño Pagando',
        'Criança Cortesia': 'Niño de Cortesía',
        'foi adicionado ao carrinho!': 'ha sido añadido al carrito!',
        'Carrinho de Compras': 'Carro de la compra',
        'CÓDIGO': 'CÓDIGO',
        'adulto': 'adulto',
        'melhor idade': 'mejor edad',
        'criança pagante': 'niño pagando',  
        'Você ainda não tem serviços adicionados no carrinho de compras.': 'Aún no ha agregado ningún servicio a su carrito de compras.',
        'Seguir para o Pagamento': 'Ir a Pago',
        'Valor final da compra': 'Valor de compra final',
        'ou em': 'o en',
        'de': 'en',
        'EXCLUIR': 'ELIMINAR',
        'Excluir': 'Eliminar',
        'Faça o login para continuar': 'Iniciar sesión para continuar',
        'Informe sua senha': 'Informar su contraseña',
        'Finalize sua Compra': 'Completa tu compra',
        'Login com Facebook': 'Iniciar sesión con Facebook',
        'Login com Google': 'Iniciar sesión con Google',
        'Voltar': 'Vuelve',
        'Deseja se cadastrar?': '¿Quieres registrarte?',
        'Clique aqui': 'Haga clic aquí',
        'Clique Aqui': 'Haga clic aquí',
        'Esqueceu a senha?': 'Olvido la contraseña?',
        '- Informe o E-mail.': '- Informar el correo electrónico.',
        '- O E-mail informado é inválido.': '- El correo electrónico ingresado no es válido.',
        'Cadastrar': 'Registrar',
        'Informe uma senha': 'Ingrese una contraseña',
        'Data de Nascimento': 'Fecha de nacimiento',
        'Informe seu celular': 'Informar a su celular',
        'Informe seu CPF': 'Ingrese su CPF',
        'Informe seu RG': 'Ingrese su RG',
        'Alterar Senha': 'Cambiar contraseña',
        'Informe a nova senha': 'Ingrese la nueva contraseña',
        'Informe o código enviado no seu e-mail': 'Ingrese el código enviado en su correo electrónico',
        'Houve um erro e não foi possível completar a requisição. Tente novamente.': 'Hubo un error y no se pudo completar la solicitud. Inténtalo de nuevo.',
        'Verifique o código que foi enviado para o seu e-mail.': 'Verifique el código que se envió a su correo electrónico.',
        '- Informe o Código.': '- Introduzca el código.',
        '- Informe a Nova Senha.': '- Introduzca una nueva contraseña.',
        'A sua senha foi alterada com sucesso.': 'Tu contraseña ha sido cambiada exitosamente.',
        'Houve um erro interno. Tente novamente ou entre em contato com a nossa equipe.': 'Hubo un error interno. Vuelva a intentarlo o póngase en contacto con nuestro equipo.',
        'Dados do Comprador': 'Datos del comprador',
        'Informe o cupom de desconto': 'Ingrese el cupón de descuento',
        'Informe os dados dos passageiros': 'Enter passenger data',
        'Pagar e finalizar meu pedido': 'Pagar y pagar mi pedido',
        'Passageiro': 'Pasajero',
        'Preencha os dados dos passageiros': 'Complete los datos del pasajero',
        'Documento': 'Documento',
        'Você pode': 'Tu puedes',
        'DADOS DO PAGAMENTO': 'DETALLES DEL PAGO',
        'Preencher Agora': 'Completar Ahora',
        'Observações': 'Comentarios',
        'Escreva uma observação': 'Escribe una nota',
        'Selecione um passageiro': 'Seleccione un pasajero',
        'Preencha os dados de pagamento': 'Complete los detalles de pago',
        'Nome escrito no cartão': 'Nombre escrito en la tarjeta',
        'Número do cartão': 'Número de tarjeta',
        'Data de Validade': 'Fecha de validez',
        'Parcelas:': 'Cuotas:',
        'Titular da Reserva': 'Titular de la reserva',
        'Nome Completo': 'Nombre Completo',
        'Detalhes da sua compra': 'Detalles de su compra',
        'Total a Pagar': 'Total a pagar',
        'DETALHES DO HOTEL': 'DETALLES DEL HOTEL',
        'compra realizada': 'compra realizada',
        'com sucesso': 'con éxito',
        'Sua compra e seu pagamento foram efetuados com sucesso': 'Tu compra y pago fueron exitosos',
        'Logo você receberá no seu e-mail uma confirmação e as informações do voucher.': 'Pronto recibirá una confirmación y la información del cupón en su correo electrónico.',
        'Seu voucher também estará agora disponível na página da sua conta aqui no site da Paraty Tours.': 'Su cupón ahora también estará disponible en la página de su cuenta aquí en el sitio web de Paraty Tours.',
        'VER MEUS PEDIDOS': 'VER MIS PEDIDOS',
        'Data da Compra': 'Fecha de Compra',
        'Total investido': 'Total',
        'Meu Perfil': 'Mi Perfil',
        'Pedidos': 'Peticiones',
        'Meus Favoritos': 'Mis favoritos',
        'Sair da minha conta': 'Cerrar sesión',
        'Local de Saída': 'Output Location',
        'Local de Destino': 'Destination',
        'Informe de onde você vai sair:': 'Let me know where you are going to leave:',
        'Informe para onde você quer ir:': 'Let me know where you want to go:',
        'Voltar ao início': 'Back to home',
        'Passeios em Brasília': 'Excursiones en Brasília',
        'Os melhores carros para o seu transporte': 'Los mejores coches para tu transporte',
        'As melhores dicas sobre a região': 'Los mejores consejos sobre la región',
        'Me Cadastrar': 'Registrame',
        'Duração Aproximada': 'Duración Aproximada',
        'A Partir de': 'Desde', 
        'Dias da Semana': 'Dias de la semana',
        'Clica em': 'Haga clic en',
        'Adicionar no Carrinho': 'Agregar al carrito',
        'para preencher os dados e adicionar esse serviço no seu': 'para completar los detalles y agregar este servicio a su',
        'pedido': 'pedido',
        'Preencha os dados para Adicionar no Carrinho': 'Rellena los datos para añadir al carrito',
        'Este serviço tem mais de 1 horário': 'Este servicio tiene más de 1 horario',
        'Deseja retirar': 'Quieres eliminar la',
        'do carinho': 'del carrito',
        'Sim': 'Sí',
        'Não': 'No',
        'Meu Pedido': 'Mi Pedido',
        'Pagamento': 'Pago',
        'Confirmação': 'Confirmación',
        "Nome": "Nombre",
        'Ir para o Pagamento': 'Ir a Pago',
        'Entrar em contato com o suporte': 'Contactar con soporte',
        'Pagamento com PayPal': 'Pago con PayPal'
      }
    }

  }
  /**
   * 
   * Get tradução
   * 
   */
   getTraducaoText(value){ 

    let lg = this.data.getLinguagem();

    return lg == 'pt-BR' ? value : this.getTraducao(value,lg);

  }

}
