import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router,Event as NavigationEvent } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'menu-partial',
  templateUrl: './menu-partial.component.html',
  styleUrls: ['./menu-partial.component.scss']
})
export class MenuPartialComponent implements OnInit {

  @Input("classCustom") classCustom = "";
  @Input("template") template       = 1;
  @ViewChild("content") content:any; 
  @ViewChild("close") close:any; 
  @ViewChild("overlay") overlay:any;    
  public show = false;

  constructor(
    private router: Router,
    private app: AppService
  ){}

  /**
   * 
   * Open
   * 
   */
  _open(){

    if(this.show){

      this.content.nativeElement.classList.remove("slide-right");
      this.content.nativeElement.classList.add("slide-left");

      setTimeout(() => {
        this.content.nativeElement.classList.remove("show");
      },200);
      this.overlay.nativeElement.classList.remove("show");
      this.close.nativeElement.classList.remove("show");
      this.show = false;

    }else{

      this.content.nativeElement.classList.add("slide-right");

      setTimeout(() => {
        this.content.nativeElement.classList.add("show");
      },200);

      this.content.nativeElement.classList.remove("slide-left"); 
      this.overlay.nativeElement.classList.add("show");
      this.close.nativeElement.classList.add("show");
      this.show = true;

    }

  }
  /**
   * 
   * On resize
   * 
   */
   onResize(){

    if(window.innerWidth >= 959){
      this.content.nativeElement.classList.remove("slide-right");
      this.content.nativeElement.classList.remove("slide-left");
    }else{
      this.classCustom = "mobile";
    }

  }
  /**
   * 
   * On events
   * 
   */
  onEvents(){

    this.router.events.forEach((event: NavigationEvent) => {
      
      if(event instanceof NavigationStart){

        if(this.app.isMobile()){

          this.content.nativeElement.classList.remove("slide-right");
          this.content.nativeElement.classList.add("slide-left");

          setTimeout(() => {
            this.content.nativeElement.classList.remove("show");
          },200);
          this.overlay.nativeElement.classList.remove("show");
          this.close.nativeElement.classList.remove("show");
          this.show = false;

        }

      }

    });


  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.onEvents();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onResize();
    },300);
  }

}
